import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import QuoteStripeLaura from '../components/quote-stripe-laura';
import NavigationButton from '../components/navigation-button';

const CodersPage = () => (
  <Layout>
    <SEO
      title="Coders"
      description="Join the ultimate competitive computer coding experience!"
      keywords={['join', 'register', 'tournament']}
    />
    <h1>Coders</h1>
    <div className="stripe-hr thin" />
    <div className="two-wide-container">
      <div>
        <div className="pricing-panel">
          <div className="panel-title">
            Join a Tournament
          </div>
          <div className="panel-body">
            <p>
              Ready to join the fun?
              Register for the Fall Tournament Series.
            </p>
            <NavigationButton
              path="/fall-2024-tournament-series/"
              text="Join the Series"
            />
          </div>
        </div>
      </div>
      <div className="pricing-panel">
        <div className="panel-title">
          Play Now
        </div>
        <div className="panel-body">
          <p>Build a Code Championship Bot to play against other coders.</p>
          <NavigationButton
            path="/code/"
            text="Play Now"
          />
        </div>
      </div>
      <div className="pricing-panel">
        <div className="panel-title">
          Learn More
        </div>
        <div className="panel-body">
          <p>What is a Bot? What is a Tournament? Check out our About Page.</p>
          <NavigationButton
            path="/about/"
            text="Learn More"
          />
        </div>
      </div>
      <div className="pricing-panel">
        <div className="panel-title">
          Top Coders
        </div>
        <div className="panel-body">
          <p>See the list of our past tournament winners - the best of the best.</p>
          <NavigationButton
            path="/top-coders/"
            text="Top Coders"
          />
        </div>
      </div>
    </div>
    <QuoteStripeLaura />
  </Layout>
);

export default CodersPage;
