import React from 'react';

const QuoteStripeLaura = () => (
  <div className="stripe-hr">
    <q>
      He really, really enjoyed this series - not just the coding part,
      but also getting to know other like-minded kids! Thanks so much for offering it!
    </q>
    <br />
    <br />
    - Laura, Parent of 4th Grade Coder
  </div>
);

export default QuoteStripeLaura;
